// Context Usando Clases
//https://stackoverflow.com/questions/41030361/how-to-update-react-context-from-inside-a-child-component
//https://medium.com/@darshana_18428/how-to-use-react-context-api-with-functional-class-components-43feadf67492
//https://mindsers.blog/en/post/updating-react-context-from-consumer/

//https://codesandbox.io/p/sandbox/infallible-platform-vo4qtv?file=%2Fsrc%2FCountController.js%3A1%2C12-1%2C28
import React, { useState } from "react";
import UserContext from "./UserContext";
import {request,reqUpLoadFile,reqUpLoadImg64 } from "./UserFetch";

function fuEstado2() {
    console.log("hola")
}
class UserProvider extends React.Component {
    constructor(props) {
        super(props);
        this.data = props;
        //const [count, setCount] = useState(0);
    }
    fuSetEstado = () => {
        //console.log("fuEstado[")
        this.setState({ uu: "jj" })
    }
    dataHarrys = {
        title: 'Datos del Usuario',
        varUser:{idUser:0,nroEmp:0
             ,nombres:null,apePat:null,apeMat:null
             },
        /*************************************    Ruta */
        arrUrl: [],
        addRuta: (stTitulo, stRuta, arrParam) => {
            this.dataHarrys.arrUrl.push({ titulo: stTitulo, ruta: stRuta, objPar: arrParam })
            //console.log("addRuta",this.dataHarrys.arrUrl)
        },
        removeRuta: (index) => {
            //console.log("Eliminando",this.dataHarrys.arrUrl.length , index)
            for (; index < this.dataHarrys.arrUrl.length - 1;) {
                //console.log("eliminaando",this.dataHarrys.arrUrl.length)
                this.dataHarrys.arrUrl.pop()
            }
            //console.log("removeRuta",this.dataHarrys.arrUrl)
        },
        removeRutaLast: () => {
            this.dataHarrys.arrUrl.pop()
            //console.log("removeRuta",this.dataHarrys.arrUrl)
            return this.dataHarrys.arrUrl.length - 1
        },

        updateRutaObjParLast: (objPar) => {
            //console.log("Ultimo ObjPar",this.dataHarrys.arrUrl[this.dataHarrys.arrUrl.length-1].objPar)
            return this.dataHarrys.arrUrl[this.dataHarrys.arrUrl.length - 1].objPar = objPar
        },
        getRuta: (index) => {
            //console.log("getRuta",this.dataHarrys.arrUrl)
            return this.dataHarrys.arrUrl[index].ruta
        },
        getObjPar: (index) => {
            return this.dataHarrys.arrUrl[index].objPar
        },
        /*************************************    Estado */
        //toggleTheme: () => {console.log("sisisisi");this.setState({kk:"ii"})},
        setStateGen: this.fuSetEstado,
        setStateGlb() { this.setStateGen() },
        setStateData: (nombre, valor) => {
            this.dataHarrys[nombre] = valor
            //console.log("Provider DataVar Set ******", nombre, valor, this.dataHarrys.dataVar)
        },
        getStateData: (nombre) => {
            //console.log("Provider DataVar Get ******", nombre, this.dataHarrys.dataVar)
            try { return this.dataHarrys[nombre]; }
            catch (e) { console.log("Error eget:****", e); return "" }
        },

        /*************************************    Fetch */
        upLoadFile(stFileObj = "attachment", path, data = "") { return reqUpLoadFile(stFileObj, path, data); },
        upLoadImg64(stFileObj = "attachment", path, data = "") { return reqUpLoadImg64(stFileObj, path, data); },
        get(path) { return request('GET', path); },
        post(path, data = {}) {  return request(this,'POST', path, { param: data });    },
        delete(path, data = {}) { return request('DELETE', path); },
        stateHarrys(instancia, pData) {
            //console.log("stateHarrys",instancia,pData)
            //instancia.context.dataUser.addRuta("p1","/ruta",{})
            //instancia.context.dataUser.
            let xrows = []
            let xrowsFirst = {}
            if (pData.rows)
                if (pData.rows.length > 0) {
                    xrowsFirst = pData.rows[0]
                    xrows = pData.rows
                }
            instancia.setState({
                  dataRows: xrows
                , data: xrowsFirst
                , regData: xrowsFirst
                , resObj: pData
                , loading: false
                , message: pData.msg
                , msg: pData.msg
                , swError: !pData.ok
            });
            //console.log("pData redirect",pData)        
            //instancia.reDirect(pData)
        }        
    }

    render() {
        // setTimeout(() => {
        //     //if (this.cuenta < 10)
        //     //this.setState({ cuenta:this.state.cuenta+1 });
        //     this.setState({ kk:"11" });
        // }, 10000);
        return (
            <UserContext.Provider value={{ dataUser: this.dataHarrys }}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}
export default UserProvider; 
